.search {
  position: relative;
  min-width: auto;
  height: auto;
  max-width: 412px;
  box-sizing: border-box;
  border: 1px solid $gray;
  box-shadow: 0.1px 0.1px 15px 0 rgba($black,0.1);
  padding: 0 98px 0 14px;
  margin: 0 0 40px;

  @include media-breakpoint-up(lg) {
    min-width: 412px;
  }

  &__text {
    height: 41px;
    width: 100%;
    font-size: 16px;
    color: $black;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 0;
    padding: 0;

    &:focus {
      color: $black;
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $black;
    }
  }

  .btn {
    position: absolute;
    height: 41.5px;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: none;
    border: none;
    outline: none;
  }
}
  
    
 
 
    
    
      