.card-contacts {
	background-color: $white;
	border-top: 1px solid $gray-light;
	border-bottom: 1px solid $gray-light;
	border-left: 1px solid $gray-light;
	border-right: 4px solid $yellow;
	padding: 24px 32px;
	margin-bottom: 48px;

	&__title {
		font-size: 24px;
		line-height: 30px;
		font-weight: $light;
		margin-bottom: 24px;
	}

	&__link {
		display: inline-flex;
		align-items: center;
		text-decoration: none;
		margin-bottom: 8px;

		.ico-svg {
			max-width: 14px;
			max-height: 19px;
			color: $yellow;
			margin-right: 16px;
		}
	}
	
}