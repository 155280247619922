.card-calendar {
	display: flex;
	background-color: $white;
	box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);
	text-decoration: none;
	margin-bottom: 56px;

	&:first-child {
		margin-bottom: 20px;
	}

	&:hover {
		box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
	}

	&__date {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content:center;
		min-width: 70px;
		background-color: $yellow;
		font-size: 16px;
		line-height: 19px;
		text-transform: uppercase;
		padding: 0 10px;

		@include media-breakpoint-up(xl) {
			min-width: 67px;
		}

		@include media-breakpoint-down(sm) {
			min-width: 70px;
		}
	}

	&__number {
		display: block;
		font-size: 28px;
		line-height: 33px;
	}

	&__wrapper {
		display: block;
		padding: 14px 21px;
	}

	&__title {
		display: block;
		font-size: 18px;
		line-height: 26px;
		font-weight: $semibold;
		margin-bottom: 8px;
	}

	&__place {
		display: block;
		font-size: 14px;
		line-height: 24px;

		.ico-svg {
			max-width: 13px;
			max-height: 17px;
			color: $yellow;
			margin-right: 6px;
		}
	}
}

.lfr-busy-day {
	font-weight: bold;
}