.page-search {
  position: relative;
  max-width: 410px;
  height: 42px;
  border: 1px solid $gray;
  box-shadow: 0.1px 0.1px 15px 0 rgba($black,0.1);
  padding-left: 10px;
  padding-right: 40px;
  margin: 0 0 40px;

  @include media-breakpoint-up(lg) {
    min-width: 410px;
  }

  &__text {
    height: 100%;
    width: 100%;
    font-size: 16px;
    color: $black;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 0;
    padding: 0;

    &:focus {
      color: $black;
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $black;
    }
  }

  &__btn {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    padding: 10px;

    .ico-svg {
      max-width: 26px;
    }
  }
}
  
  
    
 
 
    
    
      