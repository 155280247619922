.card-news {
	display: block;
	background-color: $white;
	box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);
	text-decoration: none;
	padding: 12px 21px;
	margin-bottom: 24px;
	margin-inline: 12px;

	&:hover {
		cursor: pointer;
		box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
	}

	&__date {
		display: block;
		font-size: 14px;
		line-height: 26px;
	}

	&__title {
		display: block;
		font-size: 18px;
		line-height: 26px;
		font-weight: $semibold;
	}

	&__abstract {
		display: block;
		font-size: 14px;
		line-height: 24px;
		margin-top: 5px;
	}
}

.portlet-static .portlet {
	position: static;
}