.card-members {
	height: calc(100% - 32px);
	border: 1px solid $gray-light;
	border-bottom: 2px solid $yellow;
	background-color: $white;
	padding: 16px 21px 27px 21px;
	margin-bottom: 32px;

	& > div:nth-child(1) {
		height: 154px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media-breakpoint-up(lg) {
		 	min-height: 194px;
		}
	}

	&__name {
		font-size: 18px;
		line-height: 28px;
		font-weight: $light;
		min-height: 100px;
		border-top: 1px solid $gray-light;
		padding-top: 34px; 
	}

	.ico-svg {
		max-width: 18px;
		max-height: 18px;
		color: $yellow;
		margin-right: 16px;
		margin-bottom: 8px;
	}

	&__link {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 8px;

		&:hover {
			color: $black;
		}
	}

	&__contacts {
		display: flex;
		justify-content: space-between;

		@include media-breakpoint-between(md, xl) {
			flex-direction: column;
		}

		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
	}
	
  &__img {
    //width: 100%;
    //min-height: 194px;
		margin-bottom: 30px;
		max-height: 100%;
		max-width: 100%;
		
		// @include media-breakpoint-down(sm) {
		// 	min-height: auto;
		// }

		// @include media-breakpoint-between(md, lg) {
		// 	min-height: 110px;
		// }

		// @include media-breakpoint-between(md, xl) {
		// 	min-height: 153px;
		// }
  }

}