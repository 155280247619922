.card-documents {
	position: relative;
	text-decoration: none;
	background-color: $white;
	box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);
	padding: 15px 20px;
	margin-bottom: 24px;

	&:hover {
		box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
	}

	&__format {
		height: 40px;
		min-width: 28px;
		text-align: center;
		text-transform: uppercase;
		line-height: 40px;
		font-size: 12px;
		color: $white;
		border-radius: 2px;
		padding: 0 2px;
		margin-right: 16px;

		&--pdf {
			background-color: $red;
		}

		&--xls,&--xlsx {
			background-color: $green;
		}

		&--doc,&--docx {
			background-color: $blue;
		}
		
		&--ppt,&--pptx {
			background-color: $orange;
		}
	}

	&__date {
		font-size: 14px;
		line-height: 24px;
	}

	&__title {
		display: block;
		font-size: 18px;
		line-height: 26px;
		text-decoration: none;
		font-weight: $semibold;
		margin-bottom: 4px;
	}

	&__subgroup {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 4px;
	}

	&__text {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 16px;
	}

	&__avatar {
		height: 42px;
		min-width: 42px;
		background-color: $gray-light;
		border-radius: 50%;
		text-align: center;
		line-height: 42px;
		margin-right: 16px;
	}

	&__name {
		font-size: 16px;
		line-height: 19px;
		font-weight: $semibold;
	}

	&__role {
		font-size: 16px;
		line-height: 19px;
	}

	&__icons {
		display: flex;
		position: absolute;
		top: 15px;
		right: 20px;

		a, button {
			margin-left: 12px;
		}

		.ico-svg {
			max-width: 21px;
			max-height: 21px; 
			color: $yellow;
			
			.fa-primary {
			  color: $white;
			  fill: $white;
			}

			.fa-secondary {
			  color: $yellow;
			  fill: $yellow;
			}

			&:hover, &:focus {
			  color: $yellow;
			  fill: $yellow;
			}
		}
	}
	
	.isg-favourite-star {
	  &.active {
		.ico-svg {
		  .fa-primary {
			color: $yellow;
			fill: $yellow;
			transform: translate(-10%, -11%) scale(1.2);
		  }
		}
	  }
	}

  

	&__menu {
		position: relative;
		&.open {
			.card-documents__dropdown {
				display: block;
			}
		}
	}

	&__btn {
		display: block;
		border: none;
		background-color: transparent;
		outline: none;
		margin-right: 8px;

		.ico-svg {
			max-width: 21px;
			max-height: 21px; 
			color: $yellow-light;
			margin-left: 0;
		}
	}

	&__dropdown {
		display: none;
    position: absolute;
		top: 36px;
		right: -16px;
		width: 190px;
    z-index: 2;
		background-color: $gray-light;
		padding: 12px 14px;

    &:before {
      content: "";
      position: absolute;
      top: -11px;
      right: 28px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $gray-light;
			margin-left: -5px;
			
			@include media-breakpoint-down(sm) {
				right: 47px;
			}
		}
		
		@include media-breakpoint-down(sm) {
			right: -35px;
		}
	}

	&__item {
		list-style: none;
	}

	&__link {
		display: inline-flex;
		align-items: center;
		color: $black;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 16px;
		line-height: 19px;
		padding: 12px 0;

		.ico-svg {
			max-width: 16px;
			max-height: 16px; 
			color: $black;
			margin-right: 10px;
			margin-left: 0;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}