.filters {
	display: flex;
	align-items: center;
	margin-bottom: 32px;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		align-items: flex-start;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
	}

	&__text {
		font-size: 16px;
		line-height: 19px;
		font-weight: $semibold;
		margin-right: 8px;
	}

}