.header-container {
  &__header {
    .portlet {
      position: static;
    }
  }
}

.portlet {
  margin-bottom: 0;

  .cadmin.portlet-topper {
    z-index: 1001;
  }
  
  .portlet-content,
  .portlet-minimized .portlet-content-container {
    border-width: 0;
    padding: 0;
  }

  .portlet-content {
    &-editable {
      border-width: 0;
    }
  }
}

.pager {
  font-family: $font-primario;
  font-weight: $semibold;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 30px;

  li {
    display: inline-block;

    >a, >span {
      position: relative;
      display: inline-block;
      font-family: $font-primario;
      font-weight: $semibold;
      font-size: 12px;
      border-radius: 0;
      padding: 5px 14px 2px 14px;

      &:before {
        content: "";
        position: absolute;
        top: 16px;
        width: 14px;
        height: 2px;
        background-color: $black;
      }

      &:after {
        content: "";
        position: absolute;
        top: 12px;
        border: solid $black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
      }
    }

    &:first-child {
      >a, a>span {
        padding-left: 32px;
        margin-right: 8px;

        @include media-breakpoint-down(xs) {
          margin-right: 4px;
        }
        
        &:before {
          left: 10px;
        }

        &:after {
          left: 10px;
          transform: rotate(135deg);
        }
      }
    }

    &:last-child {
      >a, a>span {
        padding-right: 32px;
        margin-left: 8px;

        @include media-breakpoint-down(xs) {
          margin-left: 4px;
        }
        
        &:before {
          right: 10px;
        }

        &:after {
          right: 10px;
          transform: rotate(-45deg);
        }
      }
    }

    &.disabled {
      >a, >span {
        &:before {
          background-color: #6c757d;
        }
  
        &:after {
          border-color: #6c757d;
        }
      }
    }
  }
}

// Liferay 6.2 styles
.taglib-discussion .lfr-discussion-actions {
  float: right;
  margin: 0;

  li {
    list-style-type: none;
  }
}
.taglib-discussion .lfr-discussion-posted-on {
  clear: both;
  float: right;
  font-size: 14px;
  line-height: 26px;
  color: $black;
  padding-top: .5em;
}
.lfr-discussion .lfr-discussion-details .user-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: $bold;
  clear: both;
  display: block;
  text-align: center;
}
.portlet-topper .portlet-title {
  font-family: $font-primario;
  font-size: 24px;
  line-height: 35px;
  font-weight: $light;
  padding: 0;
  margin-bottom: 15px;
}
.portlet-title-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
  vertical-align: top;
}
.navbar {
	.navbar-inner {
		min-height: none;
		padding: 0;
		margin: 0;
		background: none;
		border: none;
		box-shadow: none;

		.navbar-search {
			float: none;
			margin: 0;

			.input-append {
				width: 410px;
				position: relative;
				height: auto;
				box-shadow: 0.1px 0.1px 15px 0 rgba(0,0,0,0.1);
				border: 1px solid $gray;

				.search-query {
					width: 100%;
					min-height: 40px;
					border-radius: 0;
					border: none;
					box-sizing: border-box;
					font-size: 16px;
					color: $black;
					padding: 0 98px 0 14px;

					&:focus {
						box-shadow: none;
						border: none;
					}

					&::placeholder {
						color: $black;
					}
				}

				.btn {
					height: 40.5px;
					position: absolute;
					right: 0;
					z-index: 3;
					border-radius: 0;
					border: none;
					box-shadow: none;
				}
			}
		}
	}
}



// added by team politi
#wrapper {
	padding-top: 0;
}

.modal.modal-liferay {
	display: none;
}

// Fix for multiple modal opened at the same time in Liferay
.modal-open .modal {
  display: none;
}

// forgot password form
.form-login-centered {
	margin: auto;
	width: 50%;
}

body.controls-hidden .portlet > .cadmin.portlet-topper {
	display: none !important;
}

#portlet_com_liferay_login_web_portlet_LoginPortlet a.portlet-icon-back {
	display:none;
}

// hide back button Asset Publisher
.portlet-boundary_com_liferay_asset_publisher_web_portlet_AssetPublisherPortlet_ .asset-full-content.no-title .align-items-center.d-flex.mb-2 {
    display: none !important;
}

// view better widget controls
.portlet header.portlet-topper {
    z-index: 100;
}

.header-container .portlet header.portlet-topper {
	top: 20px;
}

// error page
.error-page-description {
	padding-top: 15px;
}

.error-page-go-to-home {
	margin-top: 50px;
}

.dropdown-menu > .list-unstyled {
  margin: 0;
}


.main-wrapper {
  // BLOG (list)
  .portlet-blogs {
    .widget-mode-simple-entry {
      font-family: $font-primario;
      font-weight: $regular;
      font-size: 14px;
      line-height: 24px;
      background-color: $white;
      box-shadow: .2px .2px 25px 0 rgba($black, .1);
      padding: 24px;
      margin-bottom: 20px;

      &:hover {
        box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
      }

      .title {
        font-family: $font-primario;
        font-weight: $semibold;
        font-size: 18px;
        line-height: 28px;
        text-decoration: none;
    
        a {
          font-family: $font-primario;
          font-weight: $semibold;
          font-size: 18px;
          line-height: 28px;
          text-decoration: none;
        }
      }

      .widget-metadata {
        .text-truncate-inline {
          .text-truncate {
            text-decoration: none;
            line-height: 1;
          }
        }
      }
    }

    @media (min-width: 768px) {
      .portlet .portlet-body {
        .col-md-8, .col-md-10, .entry-navigation {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .offset-md-2 {
          margin-left: 0;
        }
        .entry-navigation {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
      
    .taglib-social-bookmarks,
    .taglib-social-bookmark {
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // More Blog Entries
    .entry-navigation {
      .title {
        font-family: $font-primario;
        font-weight: $semibold;
        font-size: 18px;
        line-height: 28px;
        text-decoration: none;
    
        .title-link {
          font-family: $font-primario;
          font-weight: $semibold;
          font-size: 18px;
          line-height: 28px;
          text-decoration: none;
        }
      }
    }
  }

  // BLOGGER RECENT
  .portlet-recent-bloggers {
    .lfr-search-container {
      margin-top: 0;
    }
    .nameplate-field {
      float: left;
      margin-right: 0.5rem;
    }
    .nameplate-content {
      .heading4 {
        a {
          text-decoration: none;
          font-size: 14px;
          line-height: 1;
        }
      }
    }
    .table-bordered {
      border: 0;
    }
    .table-hover tbody tr:hover {
      background-color: transparent;
    }
    .table .table-cell {
      border: 0;
      border-bottom: solid 1px $gray;
      background-color: $white;
      padding: 24px 0;
    }
  }

  // BLOGGER LATEST
  .latest-from-blog {
    .entry {
      background-color: $white;
      box-shadow: 0.2px 0.2px 25px 0 rgba($black, 0.1);
      padding: 24px;
      margin-bottom: 20px;

      &:hover {
        box-shadow: 0.2px 0.2px 25px 0 rgba($black, 0.2);
      }
    }
    
    .entry-content .entry-author,
    .entry-content .entry-date {
      font-size: 14px;
      line-height: 24px;
      text-decoration: none;
      margin-bottom: 21px;
      
      a {
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;
      }
    }

    .entry-content {
      .entry-title h2 {
        margin-bottom: 0;

        a {
          display: block;
          font-family: $font-primario;
          font-weight: $semibold;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0px;
          text-decoration: none;
        }
      }

      .entry-categories {
        display: none;
      }

      .abstract {
        display: none;
      }

      .comments a {
        font-size: 14px;
        line-height: 24px;
        font-weight: $regular;
        color: $black;
      }
    }
    
    .entry-footer {
      display: flex;
      border-top: solid 1px $gray;
      padding-top: 20px;

      .stats {
        border-left: 1px solid $black;
        padding-left: 12px;
      }
    }

    .entry-author {
      font-size: 14px;
      color: $black;
      float: left;
      margin-right: 10px;
    }

    .entry-body {
      font-family: $font-primario;
      font-weight: $regular;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  // FORUM
  .portlet-message-boards {
    .navbar {
			background-color: $gray-light;
	
			.navbar-nav {
        margin: 0;

				.nav-item {
					.nav-link {
						display: block;
						font-size: 18px;
						line-height: 21px;
						color: $black;
						padding: 18px 12px;
						text-decoration: none;

            &.active {
              background-color: $white;
              box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);
              font-size: 18px;
              line-height: 21px;
              color: $black;
              padding: 18px 12px;

              &::after {
                content: none;
              }
            }
					}
				}
			}
		}

    .breadcrumb {
      padding: 7px;
      margin: 0;
      margin-bottom: 1.5rem;

      .breadcrumb-link {
        color: $black;
        text-decoration: underline;
      }
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }

    .list-group-notification .list-group-item {
      border-width: 1px;
    }

    .thread-container {
      .panel-heading {
        h4, h4 a {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 8px;
          font-weight: $semibold;
        }

        span.h5 {
          font-size: 14px;
          line-height: 22px;
          font-weight: $regular;

          &:not(.text-default) {
            margin-top: 8px;
          }
        }
      }
    }

    .card-tab-group .card-tab:nth-child(n+2) {
      border-color: $yellow;
    }

    .btn-group-vertical > .btn-monospaced.btn-sm,
    .btn-group-sm .btn-monospaced, .btn-monospaced.btn-sm {
      height: auto;
      width: auto;
      border: 0;
    }
  }
}

// Update calendar style for the default Liferay component
.yui3-calendar-day {
  text-align: center;
  vertical-align: super;
  height: 35px;
}

.yui3-calendar-day:hover {
  background: #BBB;
  color: $white;
}

.lfr-busy-day:after {
  content: '\25CF';
  display: block;
  font-size: 0.8em;
  margin-top: -9px;
}

// Update autocomplete style for the default Liferay component
.yui3-aclist {
  &-content {
    background-color: $white;
  }
}