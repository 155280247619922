.isg-breadcrumb {
  font-size: 12px;
  line-height: 26px;
  color: $black;
  margin-top: 24px;

  &__list {
    margin: 0;
  }
  
  &__item {
    display: inline;
		list-style-type: none;
		
		&:before {
      content: "/";
		}
		
		&:first-child {
			&:before {
				content: none;
			}
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}

		&--show-in-phone {
      display: inline-block;

      @include media-breakpoint-down(sm) {
        &:before {
          content: none;
        }
			}
		}
  }
  
  &__link {
    display: inline-block;
    color: $black;
    
    &:hover, &:focus {
      color: $black;
    }
  }
}
