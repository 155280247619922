.alphabetic-sort {
	margin-bottom: 44px;
	
	&__list {
		list-style: none;
		margin: 0;
	}

	&__item {
		display: inline-block;
		margin-right: 8px;

		@include media-breakpoint-between(md, lg) {
			margin-right: 0px;
		}
	}

	&__link {
		display: inline-block;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 18px;
		line-height: 22px;
		padding: 0 5px;

		&--active {
			background-color: $yellow;
		}

		&:hover {
			background-color: $yellow;
		}

		@include media-breakpoint-between(md, lg) {
			font-size: 16px;
		}
	}
}