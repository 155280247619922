.card-groups {
	position: relative;
	background-color: $white;
	background-image: url('../images/bg-card.png');
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);
	text-decoration: none;
	margin-bottom: 28px;

	&:hover {
		cursor: pointer;
		box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
	}

	&__wrapper {
		padding: 15px 20px 15px 12px;
	}

	&__category {
		display: inline-block;
		background-color: $yellow;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 14px;
		color: $black;
		padding: 2px 8px;
		margin-bottom: 16px;

		&--black {
			background-color: $black;
			color: $white;
		}
	}

	&__title {
		min-height: 86px;
		font-size: 24px;
		line-height: 30px;
		font-weight: $light;
		text-decoration: none;

		@include media-breakpoint-between(md, lg) {
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__notify {
		position: absolute;
		top: 18px;
		right: 20px;
		width: 26px;
		height: 26px;
	}

	&__notify-number {
		position: absolute;
		top: -4px;
		right: -8px;
		width: 20px;
		height: 16px;
		background-color: $yellow;
		text-align: center;
		line-height: 16px;
		font-size: 12px;
		border-radius: 50%;
	}

	&__text {
		font-size: 16px;
		line-height: 24px;

		@include media-breakpoint-between(md, lg) {
			font-size: 11px;
			line-height: 20px;
		}
	}

	&__foot {
		min-height: 40px;
		background-color: $gray-light;
		padding: 12px 15px;

		.ico-svg {
			max-width: 33px;
			max-height: 23px;

			@include media-breakpoint-between(md, lg) {
				max-width: 28px;
			}
		}
	}

	&__members {
		//max-width: 10px;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		margin-left: 10px;

		@include media-breakpoint-between(md, lg) {
			font-size: 10px;
			line-height: 12px;
		}

		&--number {
			font-size: 18px;
			margin: 0;

			@include media-breakpoint-between(md, lg) {
				font-size: 16px;
				line-height: 12px;
			}
		}
	}

	&__contacts {
		white-space: nowrap;

		.ico-svg {
			margin-right: 4px;
		}
	}

	.btn-link {
		&--tablet {
			@include media-breakpoint-between(md, lg) {
				font-size: 12px;
				line-height: 22px;
				padding: 10px 7px;
			}
		}
	}

	&__email {
		display: block;
		white-space: nowrap;
		text-decoration: none;
		margin-left:10px;

		&:hover {
			text-decoration: underline;
		}
	}
}