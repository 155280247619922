.isg-hamburger {
  display: block;
  min-width: 36px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  margin-right: 20px;
  
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: $white;
    border-radius: 4px;
    filter: alpha(opacity=1);
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    
    &:nth-child(1) {
      top: 4px;
    }
    
    &:nth-child(2), &:nth-child(3) {
      top: 20px;
    }
    
    &:nth-child(4) {
      top: 36px;
    }
  }
  
  &.open {
    
    span {
      &:nth-child(1) {
        width: 0%;
        left: 50%;
      }
      
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      
      &:nth-child(4) {
        width: 0%;
        left: 50%;
      }
    }
  }
}