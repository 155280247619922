.article-detail {

  &__img {
    margin-bottom: 30px;
  }

  &__date {
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 30px;
  }
}