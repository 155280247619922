.card-contents {
	border-top: 1px solid $gray-light;
	border-left: 1px solid $gray-light;
	border-bottom: 1px solid $gray-light;
	border-right: 4px solid $yellow;
	background-color: $white;
  padding: 28px 21px;
  margin-bottom: 58px;

	&__text {
		font-size: 18px;
		line-height: 26px;
    font-weight: $semibold;
    margin-bottom: 16px;
	}

	&__checkbox {
		display: inline-block;
		position: relative;
		user-select: none;
		font-size: 16px;
		line-height: 26px;
		color: $black;
		cursor: pointer;
		padding-left: 35px;
		margin-bottom: 16px;
	}

	&__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:disabled + .card-contents__mark {
      cursor: not-allowed;
      background-color: $gray-light;
      border: solid 1px $gray;
    }
    
    &:hover {
      ~ .card-contents__mark {
        color: $white;
      }
    }
    
    &:checked {
      ~ .card-contents__mark {
        background-color: $white;
        border-color: $black;
        
        &:after {
          display: block;
        }
      }
    }
	}
	
	&__mark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    cursor: pointer;
    background-color: $white;
    border: solid 1px $black;
    border-radius: 50%;
    
    &:after {
      display: none;
      content: "";
      position: absolute;
      top: 1px;
      right: 4px;
      width: 6px;
      height: 10px;
      border: solid $black;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
  
  &:hover {
    .card-contents__input {
      ~ .card-contents__mark {
        color: $black;
      }
      
      &:checked {
        ~ .card-contents__mark {
					border-color: $black;
					background-color: $white;
        }
      }
    }
  }
  
  .ico-svg {
		max-height: 24px;
		max-width: 24px;
		color: $yellow;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
  }
  
  &__number {
    width: 20px;
    height: 17px;
    border-radius: 50%;
    background-color: $yellow;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-right: 8px;
  }
}