body {
  font-family: $font-primario;
  font-weight: $regular;
  font-size: 16px;
  line-height: 26px;
  color: $black;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }

  &.signed-in {
    background-image: url('../images/bg-body.png');
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  &.no-breadcrumb {
    .internal-page__wrapper {
      padding: 64px 0;

      @include media-breakpoint-down(sm) {
        padding: 32px 0;
      }
    }
  }

  h1, h1 a, .h1,
  h2, h2 a, .h2,
  h3, h3 a, .h3,
  h4, h4 a, .h4,
  h5, h5 a, .h5,
  h6, h6 a, .h6 {
    font-family: $font-primario;
    font-weight: $light;
    margin-top: 0;
    margin-bottom: 0;
  }
  h1, h1 a, .h1 {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 30px;
  }
  h2, h2 a, .h2 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  h3, h3 a, .h3 {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  h4, h4 a, .h4 {
    font-size: 22px;
    line-height: 28px;
  }
  h5, h5 a, .h5 {
    font-size: 18px;
    line-height: 28px;
    font-weight: $semibold;
  }
  h6, h6 a, .h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: $semibold;
  }

  p {
    font-family: $font-primario;
    font-weight: $regular;
    font-size: 16px;
    line-height: 26px;
    color: $black;
    margin-bottom: 20px;
  }

  a {
    color: $black;
    font-weight: $regular;
    text-decoration: underline;

    &:hover, &:focus {
      color: $black;
      text-decoration: none;
    }
  }

  ul, ol {
    margin: 0 0 10px 25px;
    
    li {
      font-family: $font-primario;
      font-weight: $regular;
      font-size: 16px;
      line-height: 26px;
      color: $black;
    }
  }
  
  button{
    background-color: transparent;
  }

  .separator {
    border-color: $gray-light transparent $white;
    border-style: solid;
    border-width: 1px 0;
    margin: 15px auto;
  }

  strong {
    font-weight: $semibold;

    i, em {
      font-family: $font-primario;
      font-weight: $semibold;
      font-style:   italic;
      font-stretch: normal;
    }
  }

  i, em {
    font-family: $font-primario;
    font-weight:  $regular;
    font-style:   italic;
    font-stretch: normal;
  }
}

*::selection {
	color: $black;
	background-color: $yellow;
}
*::-moz-selection {
	color: $black;
	background-color: $yellow;
}
h1::selection, h1 a::selection, h2::selection,h2 a::selection, h3::selection, h3 a::selection, 
h4::selection, h4 a::selection, h5::selection, h5 a::selection, h6::selection, h6 a::selection {
	color: $black;
	background-color: $yellow;
}
h1::-moz-selection, h1 a::-moz-selection, h2::-moz-selection, h2 a::-moz-selection, h3::-moz-selection, h3 a::-moz-selection, 
h4::-moz-selection, h4 a::-moz-selection, h5::-moz-selection, h5 a::-moz-selection, h6::-moz-selection, h6 a::-moz-selection {
	color: $black;
	background-color: $yellow;
}
a::selection, a span::selection {
	color: $black;
	background-color: $yellow;
}
a::-moz-selection, a span::-moz-selection {
	color: $black;
	background-color: $yellow;
}

.btn-link, .btn {
  display: inline-block;
  height: 40px;
  font-family: $font-primario;
  font-size: 16px;
  line-height: 40px;
  font-weight: $regular;
  color: $black;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  box-shadow: none;
  cursor: pointer;
  background-color: $yellow;
  background-image: none;
  border-radius: 0;
  border: solid 1px $yellow;
  padding: 0 10px;

  @include media-breakpoint-between(md, lg) {
    width: auto;
    margin-top: 0;
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover, &:focus {
    color: $black;
    text-decoration: none;
    background-color: $yellow-light;
    border-color: $yellow-light;
  }

  &--secondary {
    background-color: $white;
    border-color: $black;

    &:hover, &:focus {
      color: $yellow;
      background-color: $white;
      border-color: $yellow;
    }
  }

  &--icon-close {
    position: relative;
    background-color: $white;
    border-color: $gray;
    outline: none;
    height: 25px;
    text-transform: none;
    font-size: 12px;
    line-height: 25px;
    padding-right: 24px;
    margin-right: 16px;
    margin-bottom: 16px;

    &:hover, &:focus {
      outline: none;
      color: $yellow;
      background-color: $white;
      border-color: $yellow;
    }

    .ico-svg {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 15px;
      height: 14px;
    }
  }

  &--icon-add {
    position: relative;
    outline: none;
    height: 25px;
    text-transform: none;
    font-size: 12px;
    line-height: 25px;
    padding-left: 24px;
    margin-bottom: 16px;

    .ico-svg {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 15px;
      height: 14px;
    }
  }

  &--filter {
    height: 25px;
    background-color: $white;
    border-color: $gray;
    text-transform: none;
    font-size: 12px;
    line-height: 14px;
    margin-right: 16px;
  
    &:hover, &:focus {
      color: $yellow;
      background-color: $white;
      border-color: $yellow;
    }

    @include media-breakpoint-down(md) {
      margin-right: 8px;
      margin-top: 8px;
    }
  }

  &.btn-primary:disabled,
  &.disabled.btn-primary {
    background-color: $yellow;
    border: solid 1px $yellow;
  }
}

.ico-svg {
  width: 100%;
  height: 100%;
}

/* responsive video */
.iframe-responsive {
  position: relative;
  z-index: 2;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* video 16:9 */
  margin-top: 80px;
  margin-bottom: 80px;
  
  &.ratio-21by9 {
    padding-bottom: 42.857143%; /* 21:9 */
  }
  &.ratio-4by3 {
    padding-bottom: 75%; /* 4:3 */
  }
  &.ratio-1by1 {
    padding-bottom: 100%; /* 1:1 */
  }
  &.ratio-1by2 {
    padding-bottom: 200%; /* 1:2 */
  }
  
  iframe, object, embed  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* visibilità testo */
.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  padding: 0;
  margin: -1px;
}

@include media-breakpoint-between(md, lg) {
  .wrapper-tablet-card {
    box-sizing: border-box;
    width: 100%;

    &--even {
      margin-right: 40px;
    }
  }
}

.clear {
  overflow: hidden;
  width: auto;
  height: auto;
}