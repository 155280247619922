.isg-navigation {
	position: relative;
	display: flex;
	align-items: center;
	// padding-right: 46px;    Ricerca commentata

	@include media-breakpoint-down(md) {
		display: none;
		position: absolute;
		top: 83px;
		left: 0;
		right: 0;
		z-index: 100;
		background-color: $black;
		border-top: 1px solid $yellow;
		margin: 0;
		padding: 16px 0;

		&.open {
      display: block;
    }
	}

	@include media-breakpoint-down(sm) {
		top: 76px;
	}

	&__list {
		display: flex;
		list-style: none;
		font-size: 18px;
		line-height: 1;
		margin: 0;
		
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}

	&__item {
		position: relative;
		font-size: 18px;
		line-height: 1;
		padding: 0 12px;
		white-space: nowrap;

		&--active {
			.isg-navigation__link {
				border-color: $yellow;
				color: $yellow;
			}
		}

		@include media-breakpoint-up(lg) {
			&:before {
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				width: 1px;
				height: 18px;
				background-color: $yellow;
			}

			&:first-child {
				&:before {
					content: none;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding: 16px;
		}
	}

	&__link {
		display: inline-block;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 18px;
		line-height: 1;
		font-weight: $semibold;
		color: $white;
		border-bottom: 3px solid $black;
		padding-top: 4px;
		padding-bottom: 4px;
		margin-top: 3px;

		&:hover {
			color: $yellow;
		}

		&:focus {
			color: $white;
		}

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
	}
}