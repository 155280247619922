.isg-user {
  position: relative;
  margin-left: 36px;

  &.open {
    .isg-user__dropdown {
      display: block;
    }
  }

//  Ricerca commentata

  // @include media-breakpoint-up(lg) {
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     top: 50%;
  //     left: -20px;
  //     right: 0;
  //     width: 1px;
  //     height: 38px;
  //     background-color: $yellow;
  //     transform: translateY(-50%);
  //   }
  // }

  &__button {
    position: relative;
    height: 50px;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    padding-right: 30px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      margin-top: -3px;
    }
  }

  &__avatar {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px;
    color: $black;
    text-align: center;
    background-color: $gray-light;
    border-radius: 50%;
  }

  &__dropdown {
    display: none;
    position: absolute;
    right: 0;
    width: 132px;
    background-color: $gray-light;
    padding: 16px;
    margin-top: 8px;

    &:before {
      content: "";
      position: absolute;
      top: -11px;
      right: 23px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $gray-light;
      margin-left: -5px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
  }

  &__link {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}