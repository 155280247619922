.isg-footer {
	background-color: $black;
	padding: 15px 0;
	margin-top: 18px;

	&__wrapper {
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}

	&__text {
		font-size: 14px;
		line-height: 20px;
		color: $white;
	}

	&__list {
		list-style: none;
		margin: 0;
		margin-left: auto;

		@include media-breakpoint-down(md) {
			margin-left: 0;
		}
	}

	&__link {
		display: block;
		text-decoration: none;
		font-size: 14px;
		line-height: 20px;
		color: $white; 

		&:hover {
			color: $yellow-light;
		}
	}

}