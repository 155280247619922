/*
* Borsa Italiana - ISG
* Copyright 2024, Ariadne
* Author: Davide Pillitteri
* 17/04/2024
* Bootstrap v4.3.1
* https://getbootstrap.com/docs/4.3/getting-started/introduction/
*/

/* Import Modules and Variables
==================================================*/
@import 'modules/bootstrap';
@import 'modules/variables';

/* Import Plug-In
==================================================*/
@import '~@staaky/fresco/dist/css/fresco.css';

/* Import Style
==================================================*/
@import 'partials/reset';
@import 'partials/styles';
@import 'partials/liferay';

.wrapper {
  @import 'partials/isg-breadcrumb';
  @import 'partials/isg-faccette';
  @import 'partials/isg-filter';
  @import 'partials/isg-footer';
  @import 'partials/isg-hamburger';
  @import 'partials/isg-header';
  @import 'partials/isg-navigation';
  @import 'partials/isg-pagination';
  @import 'partials/isg-search';
  @import 'partials/isg-sort';
  @import 'partials/isg-tab';
  @import 'partials/isg-user';

  @import 'partials/alphabetic-sort';
  @import 'partials/box-home';
  @import 'partials/filters';
  @import 'partials/hero';
  @import 'partials/internal-page';
  @import 'partials/intro';
  @import 'partials/search';
  @import 'partials/page-search';
  @import 'partials/article-detail';
  @import 'partials/box-photo-lightbox';
  @import 'partials/modal';
  // @import 'partials/blog'; // versione html lfr 6.2
  // @import 'partials/forum'; // versione html lfr 6.2

  @import 'partials/card-calendar';
  @import 'partials/card-contacts';
  @import 'partials/card-contents';
  @import 'partials/card-documents';
  @import 'partials/card-groups';
  @import 'partials/card-individuals';
  @import 'partials/card-members';
  @import 'partials/card-members-reserved';
  @import 'partials/card-news';
}
