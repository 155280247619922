.box-home {
	margin-bottom: 50px;

	&--card {
		background-color: $white;
		box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);

		&:hover {
			box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
		}
	}

	&__text {
		padding: 30px 46px;

		@include media-breakpoint-down(md) {
			padding: 30px 20px;
		}
	}

	&__link {
		display: block;
		background-color: $yellow;
		text-decoration: none;
		font-size: 24px;
		line-height: 30px;
		font-weight: $light;
		color: $black;
		padding: 18px 46px;

		.ico-svg {
      max-width: 15px;
      max-height: 15px;
			color: $black;
			margin-left: 8px;
		}
		
		&:hover {
			color: $black;
		}

		@include media-breakpoint-down(md) {
			padding: 18px 20px;
		}
	}
}