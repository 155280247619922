.isg-faccette {
  background-color: $gray-light;
  padding: 24px 0px;

  @include media-breakpoint-down(md) {
    &.open {
      background-color: $gray-light;
      position: absolute;
      right: 0;
      left: 0;

      .isg-faccette__wrapper {
        display: block;
      }

      .isg-faccette__title {
        margin-left: 15px;

        .ico-svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    z-index: 2;
    background-color: $white;
	}

  &__title {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
    font-weight: $light;
    margin: 0 24px;
	justify-content: space-between;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 19px;
      font-weight: $semibold;
      margin: 0;
    }

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      margin-left: 8px;
    }
  }
  
  &__clear {
	display: flex;
	align-items: start;
	cursor: pointer;
	font-size: 15px;
	line-height: 1;
	font-weight: 600;
  }

  &__category-box {
    &.open {
      .isg-faccette__field {
        display: flex;
      }

      .isg-faccette__category {
        .ico-svg {
          transform: rotate(180deg);    
        }
      }
    }
  }

  &__category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: $semibold;
    margin: 24px 24px 8px 24px;

    @include media-breakpoint-down(sm) {
      margin-left: 15px;
    }

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
    }
  }

  &__field {
    display: none;
    &--active {
      background-color: $white;
      border-right: 2px solid $yellow;
    }

    &--more {
      text-decoration: underline;
    }
  }

  &__link {
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    margin: 0 24px;

    @include media-breakpoint-down(sm) {
      margin-left: 15px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__viewall, &__viewless {
    font-size: 14px;
    line-height: 24px;
    margin: 0 24px;
  }

  &__number {
    font-size: 14px;
    line-height: 24px;
    margin-right: 24px;
  }

  &__wrapper {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}