.isg-filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $gray-light;
	margin-bottom: 16px;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 8px;
	}

	&__text {
		font-size: 16px;
		line-height: 30px;
	}
	
	&__email {
		color: $yellow;
		margin-left: 24px;
		
		.ico-svg {
			width:100%;
			height:100%;
			max-width: 25px;
			max-height: 25px;
		}
	}
}