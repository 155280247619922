.box-photo-lightbox {
  text-align: center;
  overflow: hidden;
  margin-bottom: 24px;

  @include media-breakpoint-up(lg) {
    &:hover {
      .box-photo-lightbox__link {
        transform: scale(1.1);
      }
      .box-photo-lightbox__title {
        bottom: 8px;
      }
    }
  }
  
  &__link {
    position: relative;
    display: block;
    height: 0;
    color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .6s ease-in-out 0s;
    padding-bottom: 56.25%;
  }
  
  &__title {
    display: block;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: -100%;
    background-color: rgba($black, 0.8);
    transition: all .6s ease-in-out 0s;
    padding: 10px 20px;
  }
}