/* Fonts
==================================================*/
// @import "~@fontsource/raleway/300.css";
// @import "~@fontsource/raleway/400.css";
// @import "~@fontsource/raleway/600.css";

// $raleway:       'Raleway', sans-serif;

$fontIsg:       "Helvetica Neue",Helvetica,Arial,sans-serif;

$font-primario: $fontIsg;

$light:         300;
$regular:       400;
$medium:        500;
$semibold:      600;
$bold:          700;
$extrabold:     900;


/* Colors
==================================================*/
$white:       #fff;

$black:       #000;

$yellow:      #ecc355;
$yellow-light:#f4dd9f;

$gray:        #dbdbdb;
$gray-light:  #efefef;
$gray-dark:   #2C2C2C;

$red:         #EE4F3C;
$orange:      #DA6337;
$green:       #0F9335;
$blue:        #004BB5;
$blue-light:  #2C6DF7;