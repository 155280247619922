.modal {

  
  .modal-header {
    padding: 32px;
  
    .modal-title {
      font-size: 32px;
      line-height: 1.2;
      white-space: normal;
    }
    
    .close {
      opacity: 1;
  
      .ico-svg {
        max-width: 40px;
        max-height: 40px;
        color: $yellow;
      }
    }
  }

  .modal-body {
    max-height: 400px;
    overflow: auto;
    padding: 32px;
  
    .modal-content {
      border: 0;
    }
	
	.panel-body {
		padding: 0;
	}
	
	.taglib-icon-list {
		margin: 0;
	}
  }
  
  .modal-form {
    width: 100%;
  
    &__box {
      margin-bottom: 24px;
    }
  
    &__label {
      font-size: 16px;
      line-height: 1;
      font-weight: $light;
      color: $black;
      margin-bottom: 8px;
    }
  
    &__input-text, &__textarea {
      display: block;
      width: 100%;
      font-size: 16px;
      line-height: 1.2;
      font-weight: $light;
      color: $black;
      appearance: none;
      background-color: $white;
      border: solid 1px #ccc;
      box-shadow: 0 0 15px 0 rgba($black,.1);
      padding: 12px 16px;
  
      &::placeholder {
        color: $black;
        opacity: .7;
      }
    }
  
    &__textarea {
      height: 200px;
    }
  }
}