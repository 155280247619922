.intro {
  background-color: $gray-light;
  padding-bottom: 30px;
	margin-bottom: 40px;

	.isg-breadcrumb {
		margin-bottom: 32px;
	}
	
	&__wrapper {
		display: flex;

		@include media-breakpoint-down(sm) {
			flex-direction: column; 
		}
	}

	&__person {
		display: flex;

		@include media-breakpoint-down(md) {
			flex-direction: column; 
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 16px;
		}
	}
	
	&__text {
		margin-right: 40px;

		@include media-breakpoint-down(md) {
			margin-bottom: 8px; 
		}
	}

	&__group {
		display: flex;
		flex-grow: 1;

		@include media-breakpoint-between(md, lg) {
			flex-grow: 0;
			margin-left: auto;
		}

		.btn-link {
			margin-left: auto;

			@include media-breakpoint-between(md, lg) {
				margin-left: 16px;
			}
		}
	}

	&__members {
		display: flex;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;

		.ico-svg {
			max-width: 33px;
			max-height: 23px;
			margin-right: 8px;
		}
	}

	&__number {
		display: block;
		font-size: 18px;
	}
}