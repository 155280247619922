.isg-header {
	position: relative;
	z-index: 4;
	background-color: $black;
	padding: 18px 0;

	&__box {
		display: flex;
		align-items: center;
	}

	&__logo {
		display: block;
		max-width: 248px;
		margin-right: 24px;
	}

	&__btn {
		border-radius: 0;
		background-color: $yellow;
		background-image: none;
		border: none;
		text-decoration: none;
		font-size: 16px;
		line-height: 26px;
		text-transform: uppercase;
		padding: 7px 16px;

		&:hover {
			background-color:$yellow-light;
			background-image: none;
		}
	}

	@include media-breakpoint-down(md) {
		.portlet {
			position: static;
		}
	}
}