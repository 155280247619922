.internal-page {
	background-repeat: no-repeat;
	background-position: top center;
	padding-top: 215px;

	@include media-breakpoint-down(sm) {
		background-image: none !important;
		padding-top: 0;
	}

	&__wrapper {
		background-color: $white;
		padding: 40px 0;

		@include media-breakpoint-down(sm) {
			padding: 32px 0;
		}
	}

	h2 {
		margin-top: 45px;
	}
}