.card-members-reserved {
	display: flex;
	border: 1px solid $gray-light;
	border-right: 4px solid $yellow;
	background-color: $white;
	padding: 16px 21px 27px 21px;
	margin-bottom: 32px;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		border-right: 1px solid $gray-light;
		border-bottom: 4px solid $yellow;
	}

	&__logo {
		width: 224px;
		margin-right: 16px;
		margin-bottom: 16px;

		@include media-breakpoint-down(md) {
			max-width: none;
			width: 100%;
			box-sizing: border-box;
			border-bottom: 1px solid $gray-light;
			padding: 32px;
			margin-right: 0;
			margin-bottom: 32px;
		}
	}

	&__tag {
		display: inline-block;
		background-color: $yellow;
		font-size: 12px;
		line-height: 14px;
		padding: 5px 16px;
	}

	&__text {
		width: 100%;
		border-left: 1px solid $gray-light;
		padding-left: 16px;

		@include media-breakpoint-down(md) {
			border-left: none;
			padding-left: 0;
		}
	}

	&__date {
		font-size: 12px;
		line-height: 14px;
		margin-top: 12px;

		@include media-breakpoint-down(md) {
			margin-bottom: 16px;
		}
	}

	&__info {
		display: flex;
		margin-right: 24px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			margin-right: 0px;
		}
	}

	&__link {
		display: inline-flex;
		text-decoration: none;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 16px;

		&:hover {
			color: $black;
		}

		.ico-svg {
			max-width: 18px;
			max-height: 18px;
			color: $yellow;
			margin-right: 16px;
			margin-top: 3px;
		}
	}

	&__people {
		margin-left: 64px;

		@include media-breakpoint-down(md) {
			margin-left: 0px;
			margin-top: 32px;
		}
	}

	&__person {
		display: flex;
		margin-top: 16px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__avatar {
		height: 42px;
		min-width: 42px;
		background-color: $gray-light;
		border-radius: 50%;
		text-align: center;
		line-height: 42px;
		margin-right: 16px;
	}

	&__name {
		display: inline-flex;
		align-items: center;
		text-decoration: none;
		font-weight: $semibold;
		font-size: 16px;
		line-height: 1;

		.ico-svg {
			max-width: 18px;
			max-height: 18px;
			color: $yellow;
			margin-left: 16px;
		}
	}

	

}