.isg-search {
  z-index: 1;
  height: 42px;

  @include media-breakpoint-down(md) {
    padding: 16px;
  }

  &__icon {
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 2px;
    color: $white;
    z-index: -3;

    .ico-svg {
      width: 20px;
      height: 20px;
      margin-top: 8px;
      margin-right: 5px;
    }

    @include media-breakpoint-down(md) {
      right: -40px;
    }
  }

  &__form {
    position: absolute;
    right: 0;
    background-color: $black;
    z-index: 2;
    margin: 0;
    
    &:hover {
      .isg-search__icon {
        color: $white;
      }
    }

    @include media-breakpoint-down(md) {
      right: 60px;
      left: 16px;
    }
  }

  &__text[type="text"] {
    position: relative;
    width: 0px;
    height: 38px;
    line-height: 38px;
    color: $white;
    background-color: transparent;
    box-shadow: none;
    border: solid 1px transparent;
    border-radius: 4px;
    transition: all .3s cubic-bezier(0,0,.23,1),border-color 1s ease;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    padding-right: 30px; 
    overflow: visible;

    &:focus {
      width: 200px;
      cursor: inherit;
      border-color: $white; 

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:hover:focus {
      cursor: inherit; 
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      border: solid 1px $white;
      transition: none;
    }
  }
}
  
  
    
 
 
    
    
      