.hero {
	display: flex;
	align-items: center;
	position: relative;
	height: 430px;
	background-color: $gray-dark;
  background-repeat: no-repeat;
	background-position: top center;
	overflow: hidden;
	margin-bottom: 68px;

	@include media-breakpoint-between(md, lg) {
		height: 310px;
	}

	@include media-breakpoint-down(sm) {
		height: 270px;
	}

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: -10%;
    bottom: 0;
		background-image: linear-gradient(-45deg, transparent 50%, $gray-dark 50%);
		opacity: 0.75;

		@include media-breakpoint-down(md) {
			background-image: none;
			background-color: $gray-dark;
		}
	}

	&__text {
		position: relative;
		z-index: 2;
	}
	
	&__title {
		max-width: 530px;
		font-size: 50px;
		line-height: 60px;
		font-weight: $light;
		color: $white;

		@include media-breakpoint-down(md) {
			font-size: 40px;
			line-height: 42px;
		}
	}

	&__description {
		max-width: 530px;
		font-size: 22px;
		line-height: 26px;
		color: $white;
		margin-top: 48px;

		@include media-breakpoint-down(md) {
			font-size: 18px;
			line-height: 26px;
			margin-top: 32px;
		}

		p {
			font-size: 22px;
			line-height: 26px;
			color: $white;
			margin-bottom: 0;
		}
	}
}