.isg-sort {
  position: relative;

  &.open {
    .isg-sort__submenu {
      display: block;
    }
    .isg-sort__btn {
      .ico-svg {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    position: relative;
		cursor: pointer;
		font-size: 16px;
		line-height: 19px;
    background-color: transparent;
	}
	
	&__order {
		font-size: 16px;
		line-height: 19px;
		font-weight: $semibold;
	}

  &__submenu {
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    top: 30px;
    z-index: 2;
    background-color: $gray-light;

    &:before {
      content: "";
      position: absolute;
      top: -11px;
      right: 23px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $gray-light;
      margin-left: -5px;
    }
  }

  &__list {
    margin: 0;
    padding: 12px;
  }

  &__item {
    list-style-type: none;
    padding-bottom: 10px;
  }

  &__link {
    color: $black;

    &:hover {
      color: $black;
    }
  }

  .ico-svg {
    color: $black;
    max-width: 10px;
    max-height: 10px;
    margin-left: 8px;
  }
}