.isg-tab {
  position: relative;
  margin-bottom: 32px;

  &__container {

    @include media-breakpoint-down(sm) {
      &.open {
        .isg-tab__list {
          display: block;
        }
  
        .isg-tab__button {
          .ico-svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__list {
    border-bottom: 1px solid $gray-light;
    z-index: 20;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      display: none;
      position: absolute;
      top: 36px;
      z-index: 3;
      max-width: 300px;
      background-color: $gray-light;
      border-bottom: none;
      padding: 12px 0;
      margin-bottom: 0;

      &:before {
        content: "";
        position: absolute;
        top: -11px;
        left: 32px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid $gray-light;
        margin-left: -5px;
      }
    }
  }

  &__link {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    padding: 12px 0;
    margin-right: 16px;
    margin-bottom: -2px;

    @include media-breakpoint-down(sm) {
      display: block;
      padding: 12px 24px;
      margin: 0;
    }

    &--active {
      border-bottom: 4px solid $yellow;

      @include media-breakpoint-down(sm) {
        border-bottom: none;
        font-weight: $semibold;
      }
    }

    &:hover, &:focus {
      color: $black;

    }
  }

  &__button {
    display: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    align-items: center;

    .ico-svg {
      color: $black;
      max-width: 16px;
      max-height: 16px;
      margin-left: 8px;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      padding: 0;
    }

    &:focus {
      outline: none;
    }
  }

  &__section {
    padding-top: 16px;
  }
}