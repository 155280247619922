.card-individuals {
	display: flex;
	text-decoration: none;
	background-color: $white;
	box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.1);
	padding: 16px;
	margin-bottom: 24px;

	@include media-breakpoint-between(md, lg) {
		padding: 12px;
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0.2px 0.2px 25px 0 rgba($black,0.2);
	}

	&__avatar {
		height: 42px;
		min-width: 42px;
		background-color: $gray-light;
		border-radius: 50%;
		text-align: center;
		line-height: 42px;
		margin-right: 16px;

		@include media-breakpoint-between(md, lg) {
			height: 36px;
			min-width: 36px;
			line-height: 36px;
			margin-right: 8px;
		}
	}

	&__text {
		display: block;
		font-size: 16px;
		line-height: 26px;

		@include media-breakpoint-between(md, lg) {
			font-size: 14px;
			line-height: 22px;
		}
	}

	&__tag {
		display: inline-block;
		background-color: $yellow;
		box-shadow: 0.2px 0.2px 15px 0 rgba($black,0.1);
		font-size: 12px;
		line-height: 14px;
		padding: 6px 16px;
		margin-right: 16px;
		margin-top: 12px;
	}

	&__contacts {
    display: block;
		text-decoration: none;
		text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
		margin-right: 16px;
		margin-top: 16px;

		.ico-svg {
			width: 16px;
			max-height: 16px;
			color: $yellow;
			margin-right: 16px;

			@include media-breakpoint-between(md, lg) {
				margin-right: 4px;
			}
		}

		@include media-breakpoint-between(md, lg) {
			font-size: 14px;
			line-height: 22px;
		}
	}

	&__info {
		overflow: hidden;
	}
}